<template>
	<div
		class="wrap__content"
		v-bind:class="{
			'wrap__content--preloader': !$store.state.userObject || !$store.getters.userPermission,
		}"
	>
		<v-preloader
			v-if="!$store.getters.userPermission || !$store.state.userObject"
			:message="errorMessage"
		></v-preloader>

		<div v-else class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['QuotaManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title w-full flex flex-wrap justify-between gap-2">
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.quotas') }}
										</div>
									</div>
									<div class="table__filter--right">
										<router-link
											v-if="$store.getters.userPermission(['change_quota'])"
											:to="'/' + $i18n.locale + '/account/quotas/create'"
											class="btn--link btn--link--plus"
										>
											Заявка на распределения квот <img src="../../../assets/img/icon-plus.svg" />
										</router-link>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter flex flex-wrap gap-3">
										<div class="table__filter--left !items-start gap-y-3">
											<div class="table__filter--search">
												<label class="search__label">
													<TheMask
														v-model="iinOrBin"
														:masked="true"
														:name="'iin_bin'"
														:placeholder="$t('headers.iin_bin')"
														:mask="['### ### ### ###']"
														type="text"
														class="input-linck"
													/>
												</label>
											</div>
											<div class="table__filter--order">
												<region
													:model-value="regions"
													:name="'regions'"
													:multiple="true"
													:placeholder="$t('labels.select_region')"
													@update:modelValue="regions = $event"
												>
												</region>
											</div>
											<div class="table__filter--order">
												<pond
													:modelValue="ponds"
													:name="'ponds'"
													:multiple="true"
													:placeholder="$t('placeholder.pond')"
													@update:modelValue="ponds = $event"
												>
												</pond>
											</div>
										</div>
										<div class="flex gap-2">
											<button class="input-btn form__line--btn" @click="find">
												{{ $t('button.find') }}
											</button>
											<button
												class="btn--link btn--link--plus"
												style="line-height: unset"
												@click.prevent.stop="clearFilter"
											>
												{{ $t('button.clear') }}
											</button>
										</div>
									</div>

									<v-data-table
										:headers="getHeaders"
										:items="list"
										:loading="false"
										:options.sync="options"
										:server-items-length="totals"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
										@update:options="paginationUserQuota"
									>
										<template v-slot:item.region="{ item }">
											{{ item.region?.title }}
										</template>
										<template v-slot:item.pond="{ item }">
											{{ item.pond?.title }}
										</template>
										<template v-slot:item.action="{ item }">
											<div class="flex">
												<router-link
													:to="'/' + $i18n.locale + '/account/quotas/' + item.id"
													class="tb__table--btn tb__table--show"
													v-tooltip.top-center="{
														content: $t('tooltip.look'),
														class: ['tooltip__btn'],
													}"
												>
													<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</router-link>
												<template>
													<router-link
														:to="'/' + $i18n.locale + '/account/quotas/' + item.id + '/edit'"
														class="tb__table--btn tb__table--show"
														v-tooltip.top-center="{
															content: $t('tooltip.edit'),
															class: ['tooltip__btn'],
														}"
													>
														<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 48 48" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#52A5FC"><path d="M37 4.5c-1.2 1.4-.9 2.1 1.8 4.7 2.6 2.7 3.3 3 4.7 1.8 1-.8 1.5-2.5 1.3-4.4-.3-2.7-.7-3.1-3.4-3.4-1.9-.2-3.6.3-4.4 1.3zM4.4 9.1c-1.7 1.9-1.9 3.8-1.9 17 0 20.1-.7 19.4 19.5 19.4 19.2 0 19.3 0 19.8-14.2.2-5.8-.1-10.4-.8-11.3-1.9-2.8-3 1.1-3 11.3 0 9.5-.1 9.8-2.6 10.7-3.3 1.3-23.5 1.3-26.9 0l-2.6-1 .3-15.2.3-15.3 9-.2C25.7 10 29 9.4 29 8c0-.6-4.6-1-11.3-1C7.4 7 6.1 7.2 4.4 9.1z"/><path d="M25.8 15.8c-6 6.2-9.2 12.2-7.4 13.9 1.7 1.7 8.6-2.1 14.6-8.1l6.4-6.6-2.9-3c-1.6-1.6-3.2-3-3.5-3-.4 0-3.6 3-7.2 6.8z"/></g></svg>
													</router-link>
													<button
														v-tooltip.top-center="{
															content: $t('tooltip.remove'),
															class: ['tooltip__btn'],
														}"
														@click="removeQuota(item.id)"
														class="item__remove"
													>
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="18" height="18" style="margin-left:15px" v-bind:svg-inline="''" :style="{ marginLeft: 0 }" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.56 7.34c0 .83.67 1.5 1.5 1.5h.8v17.72c0 2.73 2.21 4.94 4.93 4.94h12.42c2.72 0 4.93-2.21 4.93-4.94V8.84h.8c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-5.25V4.29C22.69 2.2 20.99.5 18.9.5h-5.8c-2.09 0-3.79 1.7-3.79 3.79v1.55H4.06c-.83 0-1.5.67-1.5 1.5zm18.67 5.76v11.14c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5V13.1c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5zm-8.92-8.81c0-.44.35-.79.79-.79h5.8c.44 0 .79.35.79.79v1.55h-7.38V4.29zm-1.54 8.81c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v11.14c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5V13.1z" fill="red"/></svg>
													</button>
												</template>
											</div>
										</template>
										<template v-slot:item.total="{ item }">
											{{ getFishesSum(item.fishes) }}
										</template>
										<template v-slot:item.fish="{ header, item }">
											{{ findFish(item.fishes, header.fish_id) }}
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { TheMask } from 'vue-the-mask'
import region from '@/components/components/Inputs/region'
import pond from '@/components/components/Inputs/pond'

import { api } from '@/boot/axios'

export default {
	components: {
		TheMask,
		region,
		pond,
	},
	data() {
		return {
			headers: [
				{ text: 'Id', value: 'id', width: '50px' },
				{ text: this.$t('headers.iin_bin'), value: 'iin_bin', width: '120px' },
				{ text: this.$t('headers.regions'), value: 'region' },
				{ text: this.$t('headers.pond'), value: 'pond' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '120px',
				},
				{ text: `${this.$t('headers.total')}, кг`, value: 'total' },
			],
			list: [],
			regions: [],
			ponds: [],
			iinOrBin: '',
			totals: 0,
			options: {
				itemsPerPage: 10,
				page: 1,
			},
			errorMessage: {
				status: null,
				text: null,
			},
		}
	},
	computed: {
		...mapGetters(['_getFishes']),
		getHeaders() {
			return [
				...this.headers,
				...this._getFishes
					.filter((fish) => {
						return this.list.some((item) => {
							return item.fishes.some((f) => {
								return f.fish_id === fish.id
							})
						})
					})
					.map((fish) => {
						return { text: `${fish.name}, кг`, value: 'fish', fish_id: fish.id, width: '150px' }
					}),
			]
		},
		getFishesSum() {
			return (fishes) => {
				return fishes.reduce((summ, fish) => {
					summ += fish.value
					return summ
				}, 0)
			}
		},
	},
	beforeCreate() {
		if (!localStorage.token) this.$router.push('/ru/login')
	},
	mounted() {
		this._fetchFishes()
	},
	methods: {
		...mapActions(['_fetchFishes']),
		clearFilter() {
			this.iinOrBin = null
			this.regions = []
			this.ponds = []
		},
		find() {
			this.options.page = 1
			this.paginationUserQuota(this.options)
		},
		catchError(error) {
			if (error?.response?.status == 500) {
				this.errorMessage.status = 500
				this.errorMessage.text = this.$t('system_message.500')
			}
			if (error?.response?.status == 401) {
				this.errorMessage.status = 401
				this.errorMessage.text = error.response.data.error_message
				this.$router.push('/' + this.$i18n.locale + '/login')
			}
			if (error?.response?.status == 422) {
				this.errorMessage.status = 422
				this.errorMessage.text = error.response.data.error_message
			}
			if (error?.response?.status == 403) {
				this.errorMessage.status = 403
				this.errorMessage.text = error?.response?.data?.message
			}
		},
		paginationUserQuota(options) {
			this.apiGetUserQuota(options.page, options.itemsPerPage)
		},
		async apiGetUserQuota(page = 1, itemsPerPage = 10) {
			try {
				let queries = `page=${page}&per_page=${itemsPerPage}`

				if (this.regions?.length) {
					this.regions.forEach((r) => {
						queries += `&regions[]=${r}`
					})
				}
				if (this.ponds?.length) {
					this.ponds.forEach((r) => {
						queries += `&ponds[]=${r}`
					})
				}
				if (this.iinOrBin) queries += `&iin_bin=${this.iinOrBin.replaceAll(' ', '')}`

				const response = await api.get(`/quota/list?${queries}`, {
					params: {},
				})

				if (response?.data?.data) {
					this.list = response?.data?.data
					this.totals = response?.data?.total
				}
			} catch (error) {
				this.catchError(error)
			}
		},
		async removeQuota(id) {
			try {
				await api.delete(`/quota/${id}`)
				this.apiGetUserQuota(this.options.page, this.options.itemsPerPage)
			} catch (error) {
				this.catchError(error)
			}
		},
		findFish(fishs, id) {
			return fishs.find((fish) => fish.fish_id == id)?.value || '0'
		},
	},
}
</script>
